import { useLayoutEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";


export default function  ProductsScrollTriggers(productsRef){

      function getHeight(selector){
            return productsRef.current.querySelector(selector).offsetHeight;
      }

    let headerHeight = 0;
    let heightInterval = null;

    useLayoutEffect(() => {

      headerHeight = getHeight('.c-products__gsap-header')
      heightInterval = setInterval(() => {
            if ( headerHeight > 0 ) return clearInterval(heightInterval)
            headerHeight = getHeight('.c-products__gsap-header')
      }, 100)

      const ctx = gsap.context(() => {

            const $products = {
                  el: productsRef.current,
                  deco: document.querySelector('.c-products__deco'),
                  graphic: document.querySelector('.c-product__graphics'),
                  products: document.querySelectorAll('.c-product'),
            }

            
            let gsapMatchMedia = gsap.matchMedia()

            gsapMatchMedia.add({
            isPortait: '(orientation: portrait)',
            isMobile: '(max-width: 47.9375rem)',
            isTablet: '(min-width: 48rem)'
            }, (context) => {
            let {isPortait, isMobile, isTablet} = context.conditions

            if (isMobile && isPortait) {                        

                  // ! ACTIVE PRODUCT

                  $products.products.forEach(($product, i) => {

                        // if( i === 0 ) return; // No interaction for the first one

                        let id = ( $product.getAttribute('id') ) ? $product.getAttribute('id') : `dy-product-${i}`
                        let infoSelector = `${id} .c-product__info`
                        let $info = document.querySelector('#'+infoSelector);
                        let $deco = document.querySelector(`#${id} .c-product__deco`);
                        let $ui = document.querySelector(`#${id} .c-product__ui`);

                        let availableViewport = window.innerHeight - ( headerHeight * 1.5 );
                        
                        if( $info.offsetHeight >= availableViewport ) return;

                        document.getElementById(id).classList.add('has-mobileAnimation');

                        // * Add Active
                        ScrollTrigger.create({
                              trigger: `#${id}`,
                              start: `top top+=${$info.offsetHeight/2 - headerHeight/2}`,
                              end: `bottom ${$ui.offsetHeight + headerHeight}`,
                              pin: `#${infoSelector}`,
                              pinSpacing: false,
                              // markers: true,
                              scrub: true,
                              onUpdate: self => {
                                    $info.style.opacity = 1 - `${self.progress}`
                                    $deco.style.opacity = `${self.progress}`
                                    $ui.style.setProperty('--dynamicScale', `${self.progress * 1.25}`)
                              }
                        });
                  });

            } // isMobilePortait

            if (isMobile) return // Dont execute on mobile

            // ! PRODUCTS

            // * Start
            ScrollTrigger.create({
                  trigger: $products.el,
                  start: `top 70%`,
                  end: `bottom bottom`,
                  // markers: true,
                  onEnter: () => {
                        // console.log('Products: onEnter');
                        $products.el.classList.add('is-visible');
                  },
                  onLeaveBack: () => {
                        // console.log('Products: onLeaveBack');
                        $products.el.classList.remove('is-visible');
                  },
                  onLeave: () => {
                        // console.log('Products: onLeave');
                        $products.el.classList.add('out');
                  },
                  onEnterBack: () => {
                        // console.log('Products: onEnterBack');
                        $products.el.classList.remove('out');
                  },
            });

            // ! DECO
            ScrollTrigger.create({
                  trigger: $products.deco,
                  start: `center center`,
                  end: `center center`,
                  // markers: true,
                  onEnter: () => {
                        // console.log('Deco: Enter');
                        $products.deco.classList.add('is-fixed');
                  },
                  onEnterBack: () => {
                        // console.log('Deco: EnterBack');
                        $products.deco.classList.remove('is-fixed');
                  }
            });

            // ! FIXED GRAPHIC
            ScrollTrigger.create({
                  trigger: $products.graphic,
                  start: `top top`,
                  end: `bottom bottom`,
                  // markers: true,
                  onEnter: () => {
                        // console.log('Graphics: Enter');
                        $products.el.classList.add('fixed-graphics');
                  },
                  onEnterBack: () => {
                        // console.log('Graphics: EnterBack');
                        $products.el.classList.remove('fixed-graphics');
                  }
            });

            // ! ACTIVE PRODUCT

            $products.products.forEach(($product, i) => {

                  // Undo mobile animation
                  $product.querySelector('.c-product__info').removeAttribute('style');


                  if( i === 0 ) return; // No interaction for the first one

                  let id = ( $product.getAttribute('id') ) ? $product.getAttribute('id') : `dy-product-${i}`

                  // * Add Active
                  ScrollTrigger.create({
                        trigger: `#${id}`,
                        start: `top 70%`,
                        end: `bottom bottom`,
                        // markers: true,
                        onEnter: () => {
                              // console.log(`Product[${i}]: Enter`);
                              $product.classList.add('is-active');
                        },
                  });
                  

                  // * Remove Active
                  ScrollTrigger.create({
                        trigger: `#${id}`,
                        start: `20% 70%`,
                        end: `20% 70%`,
                        onEnterBack: () => {
                              // console.log(`Product[${i}]: onEnterBack`);
                              $product.classList.remove('is-active');
                        },
                  });

            });

            }) // gsapMatchMedia


            

      }, productsRef); // <- IMPORTANT! Scopes selector text
        
        return () => ctx.revert(); // cleanup

    }, []) // UseLayoutEffect
} // ProductsScrollTriggers